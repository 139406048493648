<template>
  <iframe src="/html/love.html" width="100%" height="100%" style="border: 0"/>
</template>

<script>
export default {
  name: "love"
}
</script>

<style>

</style>
