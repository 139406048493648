<template>
  <div>
    <h1 id="text3" style="text-align: center;color: royalblue; position:fixed; top: 31%;width: 100%">{{ text3 }}</h1>
    <h1 id="text1" style="text-align: center;color: royalblue; position:fixed; top: 39%;width: 100%">{{ text1 }}</h1>
    <h1 id="text2" style="text-align: center;color: royalblue; position:fixed; top: 47%;width: 100%">{{ text2 }}</h1>
  </div>
</template>

<script>
export default {
  name: "day",
  data() {
    return {
      text1: "",
      text2: "",
      text3: ""
    }
  },
  mounted: function () {
    let time = "2021-9-4 00:00:00";
    let formatTime = time.replace(/-/g, '/')
    const start = new Date(formatTime).getTime();
    // const nowTime = new Date("2021/9/7 00:00:00");
    const nowTime = new Date().getTime();
    const check = (nowTime - start) / 1000 / 60 / 60;
    const qu = check % (24 * 3);
    let end = parseInt(qu / 24);
    end += 1;
    let result;
    switch (end) {
      case 1:
        result = "今天百班！";
        break;
      case 2:
        result = "今天夜班！";
        break;
      case 3:
        result = "今天不上班！";
        break
      default:
        result = "系统异常！" + end;
    }
    this.text1 = result;

    switch (end) {
      case 1:
        result = "明天夜班！";
        break;
      case 2:
        result = "明天不上班！";
        break
      case 3:
        result = "明天百班！";
        break;
      default:
        result = "系统异常！" + end;
    }
    this.text2 = result;

    switch (end) {
      case 1:
        result = "昨天不上班！";
        break
      case 2:
        result = "昨天百班！";
        break;
      case 3:
        result = "昨天夜班！";
        break;
      default:
        result = "系统异常！" + end;
    }
    this.text3 = result;
  }
}
</script>

<style scoped lang="scss">

</style>
