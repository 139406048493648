<template>
  <div class="i_and_her">
    <div class="top-img">
      <img src="@/assets/images/i_and_her/sun.png" class="sun">
      <img src="@/assets/images/i_and_her/cloud.png" class="cloud1">
      <img src="@/assets/images/i_and_her/cloud.png" class="cloud2">
      <img src="@/assets/images/i_and_her/cloud.png" class="cloud3">
    </div>
    <div class="info">
      <div class="head-img-info">
        <img class="head-img"
             src="http://api.admin.tqcd.vip/profile/upload/2022/05/17/b58f0bb3-1383-44f5-b79b-fbf43892c8ca.jpeg">
        <img class="hand-img" src="@/assets/images/i_and_her/cat.gif" alt="cat">
        <img class="head-img"
             src="http://api.admin.tqcd.vip/profile/upload/2022/05/17/2d860e4c-1d24-4c2c-83bc-d8f73e7ca321.jpeg">
      </div>
      <h4>祝我的宝贝<em style="color: red;font-size: 22px;">520</em>快乐！</h4>
      <p>{{ loveTimeText }}</p>
    </div>
    <div class="cZHIsPig">
      <img src="@/assets/images/i_and_her/pig2.gif" class="pig2">
      <img src="@/assets/images/i_and_her/pig1.gif" class="pig1">
    </div>
  </div>
</template>
<script>

export default {
  name: "iAndHer",
  data() {
    return {
      loveTimeText: null,
    }
  },
  methods: {
    loveTime: function () {
      const nowTime = new Date().getTime();
      // const nowTime = new Date('2022-08-28 05:55:55'.replace(/-/g,'/')).getTime();

      const iAndHerTime = new Date('2022-04-10 22:22:22'.replace(/-/g,'/')).getTime();
      const differTime = nowTime - iAndHerTime;
      const second = 1000;
      const minute = second * 60;
      const hour = minute * 60;
      const day = hour * 24;
      const year = day * 365;
      const diffSeconds = parseInt((differTime % year) % day % hour % minute / second);
      const diffMinutes = parseInt((differTime % year) % day % hour / minute);
      const diffHours = parseInt((differTime % year) % day / hour);
      const diffDays = parseInt((differTime % year) / day);
      const diffYears = parseInt(differTime / year);

      this.loveTimeText = "亲爱的咱们已经在一起 " + (diffYears > 0 ? diffYears + "年" : "") + diffDays + "天" + diffHours + "小时" + diffMinutes + "分钟" + diffSeconds + "秒啦呦！"
      // this.loveTimeText = "在一起 " + (diffYears > 0 ? diffYears + "年" : "" + diffDays + "天") + diffHours + "小时" + diffMinutes + "分钟" + diffSeconds + "秒，时间永远定格在：2022-08-28"
    }
  },
  created() {
    this.timer = setInterval(() => {
      this.loveTime();
    }, 1000);
  }
}
</script>

<style lang="scss">
.i_and_her {
  background-image: url("http://api.admin.tqcd.vip/profile/upload/2022/05/17/17fe9cc7-3b59-4932-9761-8e8e257fd9a4.jpeg");
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  .info {
    text-align: center;
    margin: auto;

    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    width: 420px;
    font-size: 16px;
    padding: 80px 20px 80px 20px;

    .head-img-info {
      .head-img {
        width: 100px;
        border-radius: 50%;
      }

      .hand-img {
        width: 100px;
      }
    }
  }

  .cZHIsPig {

    .pig2 {
      position: fixed;
      bottom: 0;
      left: 0;
      transform: scaleX(-1) rotate(-90deg);
      width: 160px;
    }

    .pig1 {
      position: fixed;
      bottom: 0;
      right: 0;
      transform: rotate(-25deg);
      width: 160px;
    }

  }

  .top-img {
    .sun {
      position: fixed;
      width: 160px;
      top: 0;
      right: 0;
    }

    .cloud1 {
      top: 20px;
      left: 0;
      width: 160px;
      position: fixed;

    }

    .cloud2 {
      top: 58px;
      left: 12%;
      width: 160px;
      position: fixed;

    }

    .cloud3 {
      top: 10px;
      left: 26%;
      width: 160px;
      position: fixed;
    }
  }
}
</style>
