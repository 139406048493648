<template>
  <div>
    <div class="user-info" :style="{paddingTop:headTop}">
      <div class="head-img">
        <template>
          <div class="demo-type">
            <div>
<!--              <el-avatar :size="80" :src="headLogo"></el-avatar>-->
            </div>
          </div>
        </template>
      </div>
<!--      <h2>{{ nickname }}</h2>-->
      <h2>&nbsp;</h2>
    </div>
    <div class="christmas-tree">
      <iframe src="/html/christmasTree.html" frameborder="0"
              :width="christmasTreeWidth"
              :height="christmasTreeHeight"></iframe>
    </div>
    <div class="text-common">
      <div>雪花中的圣诞树，照亮你内心的温暖与喜悦，愿你的圣诞充满温馨和幸福，快乐始终相伴。</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "christmasTree",
  data() {
    return {
      christmasTreeWidth: null,
      christmasTreeHeight: null,
      // headLogo: "https://thirdwx.qlogo.cn/mmopen/vi_32/y9v528IOXHa9l9PRI2QwWxI6FnAicicz6tok6Kic4OswJXos1cZgDoUKwbWCul5I4pD6yNUvrhJg3jYMfPtwwHlNA/132",
      headLogo: "http://api.admin.tqcd.vip/profile/upload/2022/05/26/b7046af4-6837-44c4-833f-e3f2f0e6a6c7.jpeg",
      nickname: "TQCD",
      headTop: "40px"
    }
  },
  mounted: function () {
    if (document.body.clientWidth < 700) {
      this.christmasTreeWidth = window.innerWidth;
      this.headTop = "40px";

      var wxOpenId;
      var routeWxOpenId = this.$route.query.wxOpenId
      if (routeWxOpenId == null) {
        wxOpenId = localStorage.getItem("wxOpenId");
      } else {
        wxOpenId = routeWxOpenId;
        localStorage.setItem("wxOpenId", routeWxOpenId);
      }
      this.$axios.get("/wxApiConfig/getWxUserinfo", {
        params: {
          wxId: "WX3E7236C38C344EAAB0D5B60F86E0CD5C",
          wxOpenId: wxOpenId,
        }
      }).then(response => {
        var result = response.data;
        if (200 === result.code) {
          alert(result.data.headimgurl)
          alert(result.data.nickname)
          alert(result.toString())
          this.headLogo = result.data.headimgurl;
          this.nickname = result.data.nickname;
        } else {
          // window.location.href = "http://api.tqcd.vip/wxApiConfig/wxWebAuthorization?wxId=WX3E7236C38C344EAAB0D5B60F86E0CD5C&scope=snsapi_userinfo&redirectUrl=http://tqcd.vip/christmasTree";
          window.location.href = "http://localhost:8001/wxApiConfig/wxWebAuthorization?wxId=WX3E7236C38C344EAAB0D5B60F86E0CD5C&scope=snsapi_userinfo&redirectUrl=http://tqcd.vip/christmasTree";
        }
      }).catch(function (err) {
        console.log("请求失败" + err)
      })
    } else {
      this.christmasTreeWidth = (window.innerWidth / 100) * 50;
      this.headTop = "99px";
    }
    this.christmasTreeHeight = (window.innerHeight / 100) * 51;
  },
}
</script>

<style scoped lang="scss">
.user-info {
  text-align: center;
  size: 20px;
  color: #268f26;
}

.text-common {
  text-align: center;
  font-size: 23px;
  color: #268f26;
  font-weight: bold;
}

.christmas-tree {
  text-align: center;
}

</style>
