<template>
  <div class="photo" id="photo">

    <div v-if="isIphone" class="isIphone">
      <div class="header">
        <el-image style="width: 140px; height: 56px"
                  src="http://api.admin.tqcd.vip/profile/upload/2022/01/13/21b95833-29da-4e68-81a2-3cceb58cdae4.png"
                  fit="fill"></el-image>
      </div>
      <div class="content">
        <template>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="精选" name="how"></el-tab-pane>
            <el-tab-pane label="最新" name="new"></el-tab-pane>
          </el-tabs>
        </template>
        <div class="howContent">精选</div>
        <div class="newContent">最新</div>
      </div>
    </div>

    <div v-else class="isMac">
      <el-row>
        <el-col :span="4">
          <div class="grid-content bg-purple-dark">&nbsp;</div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple-dark">
            <div class="header">
              <el-image style="width: 140px; height: 56px"
                        src="http://api.admin.tqcd.vip/profile/upload/2022/01/13/21b95833-29da-4e68-81a2-3cceb58cdae4.png"
                        fit="fill"></el-image>
            </div>
            <div class="content">
              <template>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="精选" name="how">精选</el-tab-pane>
                  <el-tab-pane label="最新" name="new">最新</el-tab-pane>
                </el-tabs>
              </template>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content bg-purple-dark">&nbsp;</div>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
export default {
  name: "photo",
  data() {
    return {
      availHeight: screen.availHeight,
      isIphone: document.body.clientWidth < 700,
      activeName: 'how',
      loading: true,
      howImgs: [{},
        {
          url: 'http://router.tqcd.vip:8000/?explorer/share/file&hash=9aa5J1TUfBP3dkg9MNCP0ToZZsz-kp5n9nYNfKO--ZfNTwLUWsQFYsiU88mGRB-FuSaozw',
          height: 260,
        },
        {
          url: 'http://router.tqcd.vip:8000/?explorer/share/file&hash=6888itkOLh276S7NkeNl4lNcd6LplnuC9CBaDMWT3UmGBRUzfdd_mw1wTwXzoeFiGgNCGw',
          height: 585,
        },
        {
          url: 'http://router.tqcd.vip:8000/?explorer/share/file&hash=8432edYoSR4Wnjoj9kVNv1w4zV4GWO8aotjNW973e0x5b7PTj0aDyP8t7qQCDFyP-Od-Uw',
          height: 260,
        },
        {
          url: 'http://router.tqcd.vip:8000/?explorer/share/file&hash=503bx1tpSK3_hvMUJFyH5lye7Dn7g0txJwdG6d7J6Cl92YCnIORc3P4V5SGF9G6nhzt_7g',
          height: 260,
        },
        {
          url: 'http://router.tqcd.vip:8000/?explorer/share/file&hash=191anAUc_od4mXzfoDywg8xSGJt5JtSv8DqHB9czI5K4xuZ7U15JvKqHvfCpFmQ4AW_6Ng',
          height: 585,
        },
      ],
      howImgCount:0,
      newImgs: [
        {
          url: 'http://router.tqcd.vip:8000/?explorer/share/file&hash=9aa5J1TUfBP3dkg9MNCP0ToZZsz-kp5n9nYNfKO--ZfNTwLUWsQFYsiU88mGRB-FuSaozw',
          height: 260,
        },
        {
          url: 'http://router.tqcd.vip:8000/?explorer/share/file&hash=6888itkOLh276S7NkeNl4lNcd6LplnuC9CBaDMWT3UmGBRUzfdd_mw1wTwXzoeFiGgNCGw',
          height: 585,
        },
        {
          url: 'http://router.tqcd.vip:8000/?explorer/share/file&hash=8432edYoSR4Wnjoj9kVNv1w4zV4GWO8aotjNW973e0x5b7PTj0aDyP8t7qQCDFyP-Od-Uw',
          height: 260,
        },
      ],
      newImgCount:0,
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab);
      console.log(event);
    },
    howImgLoad(){
      if (this.howImgCount < this.howImgs.length - 1) {
        this.howImgCount += 1
      }
    },
    newImgLoad(){
      if (this.newImgCount < this.newImgs.length - 1) {
        this.newImgCount += 1
      }
    }
  },
  mounted: function () {
  }
}
</script>

<style lang="scss">
.photo {
  background-color: #021320;
  padding: 0;
  color: white;

  .el-tabs__item.is-active {
    color: white;
    font-size: 20px;
  }

  .el-tabs__item {
    color: #787878;
    font-size: 20px;
  }

  .el-tabs__nav-wrap::after {
    height: 0;
  }

  .el-loading-mask {
    background-color: #393939;
  }

  .isIphone {
    .header {
      padding: 40px;
      text-align: center;
    }

    .content {
      .el-tabs__nav-scroll {
        padding: 0 22px;
      }

      .el-tabs__item {
        padding: 0 8px;
      }
    }
  }

  .isMac {
    .header {
      padding: 30px;
    }

    .content {
      .el-tabs__nav-scroll {
        padding: 0 40px;
      }

      .el-tabs__item {
        padding: 0 12px;
      }
    }
  }

}
</style>
